<template>
  <div class="monitor-forecast">
    <div class="monitor-forecast-wrap">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item
          v-for="(item, index) in cropLifeList"
          :name="item.id"
          :key="index"
        >
          <template #title>
            <div class="collapse-item-title">
              <span class="name">{{ item.name }}</span>
              <span class="time"
                >{{ item.start_date_str }}-{{ item.end_date_str }}</span
              >
            </div>
          </template>
          <div class="collapse-item-content">
            <div class="item-wrap">
              <!-- 农气灾害 -->
              <div class="disaster">
                <div class="disaster-tabs">
                  <span v-if="item.cropLifeWeatherList.length <= 0"
                    >暂无农气灾害信息</span
                  >
                  <span
                    v-else
                    class="disaster-tabs-item"
                    v-for="(disaster, dIndex) in item.cropLifeWeatherList"
                    :key="dIndex"
                  >
                    <my-btn-tag
                      @click.native="clickDisasterTab(disaster, dIndex)"
                      type="default"
                      :active="disaster.id === disasterCurrentId"
                      >{{ disaster.name }}({{ disaster.frequency }})</my-btn-tag
                    >
                    <div
                      class="warning-tip"
                      v-show="disaster.weatherForecast.length > 0"
                    ></div>
                  </span>
                </div>

                <!-- disaster内容 start -->
                <div
                  v-for="(disaster, dIndex) in item.cropLifeWeatherList"
                  :key="dIndex"
                  class="disaster-content"
                  v-show="disaster.id === disasterCurrentId"
                >
                  <div
                    class="haveDisaster"
                    v-if="disaster.weatherForecast.length > 0"
                  >
                    <div class="disaster-info">
                      <div>
                        <span>发生时间：</span>
                        <span
                          >{{ disaster.weatherForecast[0].start_date }} 至
                          {{ disaster.weatherForecast[0].end_date }}</span
                        >
                      </div>
                      <div>
                        <span>发生程度：</span>
                        <span>{{ disaster.weatherForecast[0].degree }}</span>
                      </div>
                    </div>
                    <span class="btn-wrap">
                      <van-button
                        type="info"
                        size="small"
                        @click.native="clickDisasterDetail(disaster)"
                        >详情</van-button
                      >
                    </span>
                  </div>
                  <div class="noHaveDisaster" v-else>
                    <div>暂无风险</div>
                    <span class="btn-wrap">
                      <van-button
                        type="info"
                        size="small"
                        @click.native="clickDisasterDetail(disaster)"
                        >详情</van-button
                      >
                    </span>
                  </div>
                </div>
                <!-- disaster内容 end -->
              </div>
              <!-- 虫害内容 -->
              <div class="pests">
                <div class="pests-tabs">
                  <span v-if="item.cropLifeDiseaseList.length <= 0"
                    >暂无虫害信息</span
                  >
                  <span
                    class="pests-tabs-item"
                    v-for="(pests, pIndex) in item.cropLifeDiseaseList"
                    :key="pIndex"
                  >
                    <my-btn-tag
                      @click.native="clickPestsTab(pests, pIndex)"
                      type="default"
                      :active="pests.id === pestsCurrentId"
                      >{{ pests.name }}({{ pests.frequency }})</my-btn-tag
                    >
                    <div
                      class="warning-tip"
                      v-show="pests.diseaseForecast.length > 0"
                    ></div>
                  </span>
                </div>
                <!-- disease内容 start -->
                <div
                  v-for="(pests, dIndex) in item.cropLifeDiseaseList"
                  :key="dIndex"
                  class="pests-content"
                  v-show="pests.id === pestsCurrentId"
                >
                  <div
                    class="havePests"
                    v-if="pests.diseaseForecast.length > 0"
                  >
                    <div class="pests-info">
                      <div>
                        <span>发生时间：</span>
                        <span
                          >{{ pests.diseaseForecast[0].start_date }} 至
                          {{ pests.diseaseForecast[0].end_date }}</span
                        >
                      </div>
                      <div>
                        <span>发生程度：</span>
                        <span>{{ pests.diseaseForecast[0].degree }}</span>
                      </div>
                    </div>
                    <span class="btn-wrap">
                      <van-button
                        type="info"
                        size="small"
                        @click="clickPestsDetail(pests)"
                        >详情</van-button
                      >
                    </span>
                  </div>
                  <div class="noHavePests" v-else>
                    <div>暂无风险</div>
                    <span class="btn-wrap">
                      <van-button
                        type="info"
                        size="small"
                        @click.native="clickPestsDetail(pests)"
                        >详情</van-button
                      >
                    </span>
                  </div>
                </div>
                <!-- disease内容 end -->
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 农业灾害详情 start -->
    <div>
      <van-action-sheet
        v-model="disasterActionSheetShow"
        :title="disasterForecast.name"
      >
        <div class="disaster-action">
          <div class="wrap">
            <div class="item">
              <div class="name">{{ `${disasterForecast.name}指标：` }}</div>
              <div class="ctn">{{ disasterForecast.index }}</div>
            </div>
            <div class="item">
              <div class="name">危害症状：</div>
              <div class="ctn">{{ disasterForecast.symptom }}</div>
            </div>
            <div class="item">
              <div class="name">防治建议：</div>
              <div class="ctn">{{ disasterForecast.advise }}</div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <!-- 农业灾害详情 end -->

    <!-- 病虫害详情 start -->
    <div>
      <van-action-sheet
        v-model="pestsActionSheetShow"
        :title="pestsForecast.name"
      >
        <div class="pests-action">
          <div class="wrap">
            <div
              class="item-trend"
              v-if="
                Array.isArray(pestsForecast.diseaseForecast) &&
                  pestsForecast.diseaseForecast.length > 0
              "
            >
              <div class="name">病发趋势：</div>
              <div class="ctn">
                <div
                  class="trend-ctn"
                  v-for="(item, index) in getPestsForecastTrend"
                  :key="index"
                >
                  <div>{{ item.date.slice(5) }}</div>
                  <div
                    class="degree"
                    :class="[
                      { red: item.degree == '严重' },
                      { orange: item.degree == '中等' }
                    ]"
                  >
                    {{ item.degree }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="name">危害症状：</div>
              <div class="ctn">{{ pestsForecast.symptom }}</div>
            </div>
            <div class="item">
              <div class="name">防止建议：</div>
              <div class="ctn">{{ pestsForecast.advise }}</div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <!-- 病虫害详情 end -->
  </div>
</template>

<script>
import { Collapse, CollapseItem, Button, ActionSheet } from "vant";
import {
  getMonitorForecast,
  getPestsForecastTrend
} from "@/service/serverPage/cropDetail.js";
import MyBtnTag from "@/components/server/BtnTag.vue";
export default {
  name: "MonitorForecast",
  components: {
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "van-button": Button,
    "van-action-sheet": ActionSheet,
    "my-btn-tag": MyBtnTag
  },
  props: ["cropId"],
  data() {
    return {
      activeNames: [],
      cropLifeList: [],
      disasterShow: false, // 农气灾害展示
      disasterCurrentId: "",
      pestsShow: false, // 虫害 展示
      pestsCurrentId: "",
      disasterActionSheetShow: false,
      pestsActionSheetShow: false,
      disasterForecast: {},
      pestsForecast: {},
      getPestsForecastTrend: []
    };
  },
  created() {
    getMonitorForecast(this.cropId).then(res => {
      this.cropLifeList = res.data;
      // if (Array.isArray(this.activeNames)) {
      //   this.activeNames = this.getActiveName(res.data.cropLifeList);
      // }
    });
  },
  methods: {
    getActiveName(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].current === 1) {
          return data[i].id;
        }
      }
    },
    clickDisasterTab(disaster) {
      this.disasterCurrentId = disaster.id;
    },
    clickPestsTab(pests) {
      this.pestsCurrentId = pests.id;
    },
    clickDisasterDetail(disaster) {
      this.disasterActionSheetShow = true;
      this.disasterForecast = disaster;
    },
    clickPestsDetail(pests) {
      // 获取 病虫害趋势 数据
      if (pests.diseaseForecast && pests.diseaseForecast.length > 0) {
        getPestsForecastTrend(pests.diseaseForecast[0].id).then(res => {
          this.getPestsForecastTrend = res.data;
        });
      }

      this.pestsActionSheetShow = true;
      this.pestsForecast = pests;
    }
  }
};
</script>

<style lang="scss" scoped>
.monitor-forecast {
  box-sizing: border-box;
  color: #333;
  .monitor-forecast-wrap {
    .collapse-item-title {
      .name {
        font-weight: 700;
        margin-right: 10px;
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          vertical-align: middle;
          margin-right: 4px;
          background: #000;
          border-radius: 50%;
        }
      }
    }
    .collapse-item-content {
      .disaster,
      .pests {
        margin: 6px 0;
        padding: 2px;
        border: 1px solid #ccc;
        border-radius: 2px;
        .disaster-tabs,
        .pests-tabs {
          display: flex;
          flex-wrap: wrap;
          .disaster-tabs-item,
          .pests-tabs-item {
            margin: 4px;
            position: relative;
            .warning-tip {
              width: 10px;
              height: 10px;
              background: #f64040;
              border-radius: 50%;
              position: absolute;
              right: -4px;
              top: -4px;
            }
          }
        }
        .disaster-content,
        .pests-content {
          padding: 4px;
          color: #333;
          .haveDisaster,
          .havePests,
          .noHaveDisaster,
          .noHavePests {
            position: relative;
            .btn-wrap {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  .disaster-action,
  .pests-action {
    .wrap {
      padding: 10px 10px 14px;
      font-size: 14px;
      line-height: 1.5;
      .item {
        margin: 16px 0;
        .name {
          font-weight: 600;
        }
        .ctn {
          margin-top: 8px;
          border: 1px solid #ddd;
          padding: 10px;
          border-radius: 2px;
        }
      }
      .item-trend {
        margin: 16px 0;
        .name {
          font-weight: 600;
        }
        .ctn {
          display: flex;
          .trend-ctn {
            padding: 5px 10px;
            text-align: center;
            .degree {
              margin-top: 6px;
              &.red {
                color: #fff;
                background: #ee0a24;
              }
              &.orange {
                color: #fff;
                background: #ff976a;
              }
            }
          }
        }
      }
    }
  }
}
</style>
