<template>
  <div class="crop-detail">
    <van-nav-bar
      :title="position"
      left-text="返回"
      left-arrow
      @click-left="onNavClick"
    />
    <div class="corp-detail-wrap">
      <!-- 天气实况 -->
      <div class="live-weather">
        <my-live-weather :lngLat="lngLat" />
      </div>
      <!-- 田地名称 -->
      <div class="field-name">
        <div>{{ cropInfo.land && cropInfo.land.name }}</div>
      </div>
      <div class="crop-info">
        <div class="info-top">
          <div class="crop-img">
            <div style="width: 100%; height: 100%">
              <img
                v-if="cropInfo.cropImage"
                :src="cropInfo.cropImage"
                style="width: 100%; height: 100%; background: #ddd"
              />
              <div
                v-else
                style="width: 100%; height: 100%; background: #ddd"
              ></div>
            </div>
          </div>
          <div class="crop-detail">
            <div class="item-detail">
              <div>{{ cropInfo.crop_type_name }}</div>
              <div>
                {{ cropInfo.currentCropLife && cropInfo.currentCropLife.name }}
              </div>
              <div>{{ cropInfo.cultivation_condition_name }}</div>
              <div class="item-detail-carbon" v-if="cropInfo.carbon_num!==0">{{'碳 '+cropInfo.carbon_num}}</div>
            </div>
            <div class="item-detail" style="padding: 6px 0">
              {{ this.plantTypeLabel }}：
              <div>{{ cropInfo.plant_time }}</div>
              <div v-if="cropInfo.after_plant_days > 0">
                {{ this.afterPlantDays }}
              </div>
            </div>
            <div class="item-detail">
              预计收获：{{ cropInfo.expect_harvest_time }}
            </div>
          </div>
        </div>
        <div class="info-bottom">
          <div
            class="info-item"
            v-if="
              cropInfo.currentCropLife &&
              cropInfo.currentCropLife.phenologyOperList &&
              cropInfo.currentCropLife.phenologyOperList.length > 0
            "
          >
            <div class="label">农事操作：</div>
            <div class="content">
              <span
                v-for="(item, index) in cropInfo.currentCropLife
                  .phenologyOperList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div
            class="info-item"
            v-if="
              cropInfo.currentCropLife &&
              cropInfo.currentCropLife.cropLifeDiseaseList &&
              cropInfo.currentCropLife.cropLifeDiseaseList.length > 0
            "
          >
            <div class="label">易发病虫害：</div>
            <div class="content">
              <span
                v-for="(item, index) in cropInfo.currentCropLife
                  .cropLifeDiseaseList"
                :key="index"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
          <div
            class="info-item"
            v-if="
              cropInfo.currentCropLife &&
              cropInfo.currentCropLife.cropLifeWeatherList &&
              cropInfo.currentCropLife.cropLifeWeatherList.length > 0
            "
          >
            <div class="label">易发农业气象灾害：</div>
            <div class="content">
              <span
                v-for="(item, index) in cropInfo.currentCropLife
                  .cropLifeWeatherList"
                :key="index"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="handle-btn">
          <div
            class="btn-wrap"
            @click="handleReplant(cropInfo.land_id, cropInfo.id)"
          >
            改种
          </div>
          <div class="btn-wrap" @click="handleHarvest(cropInfo.id)">收获</div>
        </div>
      </div>

      <!--作物相关相关的信息-->
      <div class="field-info">
        <div class="field-tab">
          <van-tabs
            type="card"
            v-model="activeTab"
            color="#1989fa"
            @change="changeTabs"
          >
            <van-tab
              title="种植规划"
              name="zzgh"
              title-class="my-van-tab"
              replace
            >
              <div class="my-tab-ctn">
                <my-planting-plan :cropId="id" ref="zzgh" />
              </div>
            </van-tab>
            <van-tab
              title="历史农事"
              name="lsns"
              title-class="my-van-tab"
              replace
            >
              <div class="my-tab-ctn">
                <my-historical-farm :cropId="id" ref="lsns" />
              </div>
            </van-tab>
            <van-tab
              title="监测预报"
              name="jceyb"
              title-class="my-van-tab"
              replace
            >
              <div class="my-tab-ctn">
                <my-monitor-forecast :cropId="id" />
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Tab, Tabs, Dialog, Notify } from "vant";
import MyLiveWeather from "./liveWeather.vue";
import {
  getCropById,
  postCropHarvest
} from "@/service/serverPage/cropDetail.js";
import MyPlantingPlan from "./cropDetail/plantingPlan.vue";
import MyHistoricalFarm from "./cropDetail/historicalFarm.vue";
import MyMonitorForecast from "./cropDetail/monitorForecast.vue";
export default {
  name: "CropDetail",
  components: {
    "van-nav-bar": NavBar,
    "van-tabs": Tabs,
    "van-tab": Tab,
    "my-live-weather": MyLiveWeather,
    "my-planting-plan": MyPlantingPlan,
    "my-historical-farm": MyHistoricalFarm,
    "my-monitor-forecast": MyMonitorForecast
  },
  props: ["id"],
  data() {
    return {
      lngLat: {},
      position: "",
      activeTab: "zzgh",
      cropInfo: {}
    };
  },
  computed: {
    plantTypeLabel: function () {
      let tempValue = "";
      if (this.cropInfo && this.cropInfo.cultivation_type === "zb") {
        tempValue = "播种";
      } else if (this.cropInfo && this.cropInfo.cultivation_type === "ymyz") {
        tempValue = "移栽";
      } else if (this.cropInfo && this.cropInfo.cultivation_type === "dnszp") {
        tempValue = "萌芽";
      }
      return tempValue;
    },
    afterPlantDays: function () {
      let tempValue = "";
      if (this.cropInfo && this.cropInfo.after_plant_days > 0) {
        tempValue = `${this.plantTypeLabel}后${this.cropInfo.after_plant_days}天`;
      } else if (this.cropInfo && this.cropInfo.after_plant_days < 0) {
        tempValue = `还未${this.plantTypeLabel}`;
      }
      return tempValue;
    }
  },
  methods: {
    onNavClick() {
      this.$router.go(-1);
    },
    // 通过汉语地址 获取 经纬度
    amapGeocoder(position) {
      return new Promise((resolve, reject) => {
        AMap.plugin("AMap.Geocoder", function () {
          var geocoder = new AMap.Geocoder();
          geocoder.getLocation(position, function (status, result) {
            if (status === "complete" && result.geocodes.length) {
              var lnglat = result.geocodes[0].location;
              resolve(lnglat);
            } else {
              reject(new Error("根据地址查询位置失败"));
            }
          });
        });
      });
    },
    // tabs切换
    changeTabs(index) {
      if (index === "zzgh") {
        this.$refs.zzgh.radio = "";
      }
      if (index === "lsns") {
        this.$refs.lsns && this.$refs.lsns.historyList();
      }
    },
    // 作物收获
    handleHarvest(id) {
      Dialog.confirm({
        title: "作物收获",
        message: "确认收获后，作物相关信息可在我的》地块管理》种植历史中查看"
      }).then(() => {
        postCropHarvest(id).then((res) => {
          if (res.code === 200) {
            this.$router.push({ path: "/server" });
            Notify({ type: "success", message: "操作成功" });
          } else {
            Notify({ type: "warning", message: "操作失败" });
          }
        });
      });
    },
    // 作物改种
    handleReplant(fieldId, cropId) {
      this.$router.push({ path: `/addCrop/${fieldId}/${cropId}` });
    }
  },
  mounted() {
    getCropById(this.id).then((res) => {
      this.cropInfo = res.data;
      const position = `${res.data.land.province_name}${res.data.land.city_name}${res.data.land.county_name}`;
      this.position = `${res.data.land.city_name} ${res.data.land.county_name}`;
      this.amapGeocoder(position).then((res) => {
        this.lngLat = {
          lat: res.lat,
          lng: res.lng
        };
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.crop-detail {
  box-sizing: border-box;
  .corp-detail-wrap {
    margin: 0 10px 10px;
    .field-name {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin: 20px;
    }
    .crop-info {
      border: 1px solid #ddd;
      padding: 4px;
      .info-top {
        display: flex;
        height: 70px;
        margin-bottom: 10px;
        .crop-img {
          flex: 0 0 80px;
        }
        .name {
          font-size: 14px;
          text-align: center;
        }
        .crop-detail {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .item-detail {
            display: flex;
            align-items: center;
            div + div {
              margin-left: 10px;
            }
            .item-detail-carbon {
              background: #d0e17d;
              padding: 0 8px;
              margin-left: 16px;
            }
          }
        }
      }
      .info-bottom {
        margin-top: 20px;
        .info-item {
          display: flex;
          padding: 4px 0;
          .label {
            flex: 0 0 auto;
            font-weight: 600;
          }
          .content {
            span + span {
              margin-left: 6px;
            }
          }
        }
      }
      .handle-btn {
        display: flex;
        margin: 8px 0;
        justify-content: flex-end;
        .btn-wrap {
          font-size: 14px;
          background: #07c160;
          padding: 4px 8px;
          color: #fff;
          border-radius: 4px;
          & + .btn-wrap {
            margin-left: 10px;
          }
        }
      }
    }
    .field-info {
      margin-top: 20px;
      .field-info-common {
        display: flex;
        .img {
          width: 86px;
          height: 70px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name-and-owner {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .field-tab {
        margin-top: 20px;
        .my-tab-ctn {
          margin-top: 10px;
          border-left: 2px dashed #333;
        }
      }
    }
  }
}
</style>
