import server from "../axios.config.js";

const baseURL = "http://110.87.103.59:18086/";
// 地块详情 byId
export const getCropById = function(id) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: "farmfriend/landCrop/getById",
    params: {
      id
    }
  });
};

// 种植规划list
export const getPlantingPlan = function(landCropId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: "/farmfriend/landCrop/selectPlanList",
    params: {
      landCropId
    }
  });
};

// 种植规划 操作记录（type:save新增；update更新）
export const postHandleContent = function(
  type,
  landId,
  cropLifeOperId,
  content,
  id
) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: `/croplifeoperrec/${type}`,
    data: {
      land_id: landId,
      crop_life_oper_id: cropLifeOperId,
      content,
      id: id
    }
  });
};

// 历史农事  list
export const getHistoryList = function(landCropId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: "/farmfriend/phenologyoperrec/getHistoryList",
    params: {
      landCropId
    }
  });
};

// 监测预报 list
export const getMonitorForecast = function(landCropId) {
  return server({
    method: "get",
    baseURL: baseURL,
    url: "/farmfriend/forecast/selectList",
    params: {
      landCropId
    }
  });
};

// 病虫害趋势
export const getPestsForecastTrend = function(forecastId) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/farmfriend",
    url: "/forecast/selectDiseaseTrend",
    params: {
      forecastId
    }
  });
};

// 作物改种
export const postCropReplant = function(options) {
  return server({
    method: "post",
    baseURL: baseURL,
    url: "/farmfriend/landCrop/replant",
    data: options
  });
};

// 作物收获
export const postCropHarvest = function(id) {
  return server({
    method: "post",
    baseURL: baseURL,
    url: "/farmfriend/landCrop/harvest",
    data: {
      id
    }
  });
};

// 种植规划 记录
export const postPhenologyoperrec = function(options) {
  const data = new FormData();
  data.append("land_crop_id", options.landCropId);
  data.append("phenology_oper_id", options.id);
  data.append("content", options.content);
  options.files.forEach(fileItem => {
    data.append("files", fileItem.file);
  });

  return server({
    method: "post",
    baseURL: baseURL,
    url: "/farmfriend/phenologyoperrec/save",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: data
  });
};
