<template>
  <div class="planting-plan">
    <van-collapse v-model="activeNames" accordion>
      <van-collapse-item
        v-for="(item, index) in planList"
        :key="index"
        :name="item.id"
      >
        <template #title>
          <div class="collapse-item-title">
            <span class="bold-color">{{ item.name }}</span>
            <span class="light-color"
              >{{ item.start_date_str }}-{{ item.end_date_str }}</span
            >
          </div>
        </template>
        <div class="collapse-item-content">
          <div class="collapse-item-content-wrap">
            <van-radio-group v-model="radio">
              <div
                v-for="(cItem, cIndex) in item.phenologyOperList"
                :key="cIndex"
                class="collapse-item-content-item"
              >
                <van-radio
                  :name="cItem.id"
                  shape="square"
                  @click="radioClick(cItem)"
                  >{{ cItem.name }}</van-radio
                >
                <div class="handle-wrap" v-show="cItem.id === radio">
                  <div class="text-area">
                    <van-field
                      v-model="handleContent"
                      rows="3"
                      autosize
                      type="textarea"
                      placeholder="请输入记录"
                    />
                  </div>
                  <div class="uploader-area">
                    <van-uploader v-model="fileList" :after-read="afterRead" />
                  </div>
                  <div class="record-btn">
                    <van-button
                      type="info"
                      size="small"
                      @click.native.stop="addComment(cItem)"
                      >记录</van-button
                    >
                  </div>
                </div>
              </div>
            </van-radio-group>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import {
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  Field,
  Button,
  Notify,
  Uploader,
  Dialog
} from "vant";
import {
  getPlantingPlan,
  postPhenologyoperrec
} from "@/service/serverPage/cropDetail.js";

export default {
  name: "PlantingPlan",
  components: {
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "van-radio-group": RadioGroup,
    "van-radio": Radio,
    "van-field": Field,
    "van-button": Button,
    "van-uploader": Uploader
  },
  props: ["cropId"],
  data() {
    return {
      activeNames: [],
      fileList: [],
      radio: "",
      handleContent: "",
      planList: {},
      cropLifeOperList: []
    };
  },
  created() {
    this.axiosPlantingPlan();
  },
  methods: {
    axiosPlantingPlan() {
      getPlantingPlan(this.cropId).then(res => {
        this.planList = res.data;
        if (Array.isArray(this.activeNames)) {
          this.activeNames = this.getActiveName(res.data);
        }
      });
    },
    // 获取当前生长期id，并打开collapse
    getActiveName(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].current === 1) {
          return data[i].id;
        }
      }
    },
    radioClick(cropLife) {
      const { comment } = cropLife;
      this.handleContent = comment || "";
    },
    afterRead(file) {
    },
    addComment(cItem) {
      const id = cItem.id; // => phenology_oper_id
      const landCropId = this.cropId;
      // 上传的内容
      const content = this.handleContent;
      // 上传的图片
      const files = this.fileList;

      Dialog.confirm({ title: "操作记录", message: "是否记录当前操作" }).then(
        () => {
          postPhenologyoperrec({
            id,
            landCropId,
            content,
            files
          }).then(res => {
            if (res.code === 200) {
              Notify({ type: "success", message: "记录成功" });
              this.handleContent = "";
              this.fileList = [];
            } else {
              Notify({ type: "danger", message: "记录失败,请重新尝试" });
            }
          });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-item-title {
  .bold-color {
    font-weight: bold;
    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      vertical-align: middle;
      margin-right: 4px;
      background: #000;
      border-radius: 50%;
    }
  }
  .light-color {
    margin-left: 10px;
  }
}
.collapse-item-content {
  .collapse-item-content-wrap {
    .collapse-item-content-item {
      &:not(:first-child) {
        margin: 20px 0;
      }
      .uploader-area {
        text-align: left;
        border: 1px solid #ddd;
        padding: 10px 0 0 10px;
      }
      .record-btn {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.planting-plan .text-area .van-field {
  padding: 10px 0 6px;
  .van-field__control {
    padding: 4px;
    border: 1px solid #ddd;
  }
}
</style>
