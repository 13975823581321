<template>
  <div class="history-farm">
    <template v-if="Object.keys(historyFarmList).length > 0">
      <div class="history-farm-wrap">
        <van-collapse v-model="activeNames">
          <van-collapse-item
            v-for="(item, key) in historyFarmList"
            :name="key"
            :key="key"
          >
            <template #title>
              <div class="collapse-item-title">
                <span class="time">{{ key }}</span>
                <span class="btn-tag">
                  <span
                    class="btn-tag-item"
                    v-for="(cItem, index) in item"
                    :key="index"
                  >
                    <my-btn-tag>
                      {{ cItem.phenology_oper_name }}
                    </my-btn-tag>
                  </span>
                </span>
              </div>
            </template>
            <div class="collapse-item-content">
              <div
                class="handle-item"
                v-for="(cItem, index) in item"
                :key="index"
              >
                <div class="text-area">
                  <span class="item-title">
                    {{ cItem.phenology_oper_name }}:
                  </span>
                  <span class="item-content">{{ cItem.content }}</span>
                </div>
                <div class="image-wrap">
                  <div
                    class="image-item"
                    v-for="(image, index) in cItem.infoAttachList"
                    :key="index"
                    @click="imageShow(image.full_path)"
                  >
                    <img :src="image.full_path" />
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <van-overlay :show="overlayShow" @click="overlayShow = false">
          <div style="width: 100vw;" class="overlay-content-wrapper">
            <img style="width: 100%;" :src="imageUrl" />
          </div>
        </van-overlay>
      </div>
    </template>
    <template v-else>
      <div style="padding-left: 10px;">
        此田块暂无操作记录
      </div>
    </template>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Overlay } from "vant";
import MyBtnTag from "@/components/server/BtnTag.vue";
import { getHistoryList } from "@/service/serverPage/cropDetail.js";
export default {
  name: "HistoryFarm",
  components: {
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "van-overlay": Overlay,
    "my-btn-tag": MyBtnTag
  },
  props: ["cropId"],
  data() {
    return {
      activeNames: [],
      historyFarmList: {},
      overlayShow: false,
      imageUrl: ""
    };
  },
  created() {
    this.historyList();
  },
  methods: {
    historyList() {
      getHistoryList(this.cropId).then(res => {
        this.historyFarmList = res.data;
      });
    },
    imageShow(url) {
      this.imageUrl = url;
      this.overlayShow = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.history-farm {
  box-sizing: border-box;
  color: #333;
  .history-farm-wrap {
    .collapse-item-title {
      display: flex;
      .time {
        font-weight: 600;
        flex: 0 0 100px;
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          vertical-align: middle;
          margin-right: 4px;
          background: #000;
          border-radius: 50%;
        }
      }
      .btn-tag {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        .btn-tag-item {
          margin: 3px;
        }
      }
    }
    .collapse-item-content {
      .handle-item {
        margin: 6px 0;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 4px 2px;
        color: #333;
        .item-title {
          font-weight: 600;
        }
        .image-wrap {
          display: flex;
          .image-item {
            margin-top: 10px;
            width: 80px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .overlay-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
