var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crop-detail"},[_c('van-nav-bar',{attrs:{"title":_vm.position,"left-text":"返回","left-arrow":""},on:{"click-left":_vm.onNavClick}}),_c('div',{staticClass:"corp-detail-wrap"},[_c('div',{staticClass:"live-weather"},[_c('my-live-weather',{attrs:{"lngLat":_vm.lngLat}})],1),_c('div',{staticClass:"field-name"},[_c('div',[_vm._v(_vm._s(_vm.cropInfo.land && _vm.cropInfo.land.name))])]),_c('div',{staticClass:"crop-info"},[_c('div',{staticClass:"info-top"},[_c('div',{staticClass:"crop-img"},[_c('div',{staticStyle:{"width":"100%","height":"100%"}},[(_vm.cropInfo.cropImage)?_c('img',{staticStyle:{"width":"100%","height":"100%","background":"#ddd"},attrs:{"src":_vm.cropInfo.cropImage}}):_c('div',{staticStyle:{"width":"100%","height":"100%","background":"#ddd"}})])]),_c('div',{staticClass:"crop-detail"},[_c('div',{staticClass:"item-detail"},[_c('div',[_vm._v(_vm._s(_vm.cropInfo.crop_type_name))]),_c('div',[_vm._v(" "+_vm._s(_vm.cropInfo.currentCropLife && _vm.cropInfo.currentCropLife.name)+" ")]),_c('div',[_vm._v(_vm._s(_vm.cropInfo.cultivation_condition_name))]),(_vm.cropInfo.carbon_num!==0)?_c('div',{staticClass:"item-detail-carbon"},[_vm._v(_vm._s('碳 '+_vm.cropInfo.carbon_num))]):_vm._e()]),_c('div',{staticClass:"item-detail",staticStyle:{"padding":"6px 0"}},[_vm._v(" "+_vm._s(this.plantTypeLabel)+"： "),_c('div',[_vm._v(_vm._s(_vm.cropInfo.plant_time))]),(_vm.cropInfo.after_plant_days > 0)?_c('div',[_vm._v(" "+_vm._s(this.afterPlantDays)+" ")]):_vm._e()]),_c('div',{staticClass:"item-detail"},[_vm._v(" 预计收获："+_vm._s(_vm.cropInfo.expect_harvest_time)+" ")])])]),_c('div',{staticClass:"info-bottom"},[(
            _vm.cropInfo.currentCropLife &&
            _vm.cropInfo.currentCropLife.phenologyOperList &&
            _vm.cropInfo.currentCropLife.phenologyOperList.length > 0
          )?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("农事操作：")]),_c('div',{staticClass:"content"},_vm._l((_vm.cropInfo.currentCropLife
                .phenologyOperList),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item.name))])}),0)]):_vm._e(),(
            _vm.cropInfo.currentCropLife &&
            _vm.cropInfo.currentCropLife.cropLifeDiseaseList &&
            _vm.cropInfo.currentCropLife.cropLifeDiseaseList.length > 0
          )?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("易发病虫害：")]),_c('div',{staticClass:"content"},_vm._l((_vm.cropInfo.currentCropLife
                .cropLifeDiseaseList),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e(),(
            _vm.cropInfo.currentCropLife &&
            _vm.cropInfo.currentCropLife.cropLifeWeatherList &&
            _vm.cropInfo.currentCropLife.cropLifeWeatherList.length > 0
          )?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"label"},[_vm._v("易发农业气象灾害：")]),_c('div',{staticClass:"content"},_vm._l((_vm.cropInfo.currentCropLife
                .cropLifeWeatherList),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e()]),_c('div',{staticClass:"handle-btn"},[_c('div',{staticClass:"btn-wrap",on:{"click":function($event){return _vm.handleReplant(_vm.cropInfo.land_id, _vm.cropInfo.id)}}},[_vm._v(" 改种 ")]),_c('div',{staticClass:"btn-wrap",on:{"click":function($event){return _vm.handleHarvest(_vm.cropInfo.id)}}},[_vm._v("收获")])])]),_c('div',{staticClass:"field-info"},[_c('div',{staticClass:"field-tab"},[_c('van-tabs',{attrs:{"type":"card","color":"#1989fa"},on:{"change":_vm.changeTabs},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('van-tab',{attrs:{"title":"种植规划","name":"zzgh","title-class":"my-van-tab","replace":""}},[_c('div',{staticClass:"my-tab-ctn"},[_c('my-planting-plan',{ref:"zzgh",attrs:{"cropId":_vm.id}})],1)]),_c('van-tab',{attrs:{"title":"历史农事","name":"lsns","title-class":"my-van-tab","replace":""}},[_c('div',{staticClass:"my-tab-ctn"},[_c('my-historical-farm',{ref:"lsns",attrs:{"cropId":_vm.id}})],1)]),_c('van-tab',{attrs:{"title":"监测预报","name":"jceyb","title-class":"my-van-tab","replace":""}},[_c('div',{staticClass:"my-tab-ctn"},[_c('my-monitor-forecast',{attrs:{"cropId":_vm.id}})],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }